exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./assets/fonts/web/National2Web-Light.eot"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./assets/fonts/web/National2Web-Light.eot") + "?#iefix");
var ___CSS_LOADER_URL___2___ = urlEscape(require("./assets/fonts/web/National2Web-Light.woff"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./assets/fonts/web/National2Web-Regular.eot"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("./assets/fonts/web/National2Web-Regular.eot") + "?#iefix");
var ___CSS_LOADER_URL___5___ = urlEscape(require("./assets/fonts/web/National2Web-Regular.woff"));
var ___CSS_LOADER_URL___6___ = urlEscape(require("./assets/fonts/web/National2Web-Medium.eot"));
var ___CSS_LOADER_URL___7___ = urlEscape(require("./assets/fonts/web/National2Web-Medium.eot") + "?#iefix");
var ___CSS_LOADER_URL___8___ = urlEscape(require("./assets/fonts/web/National2Web-Medium.woff"));
var ___CSS_LOADER_URL___9___ = urlEscape(require("./assets/fonts/web/National2Web-Bold.eot"));
var ___CSS_LOADER_URL___10___ = urlEscape(require("./assets/fonts/web/National2Web-Bold.eot") + "?#iefix");
var ___CSS_LOADER_URL___11___ = urlEscape(require("./assets/fonts/web/National2Web-Bold.woff"));
var ___CSS_LOADER_URL___12___ = urlEscape(require("./assets/fonts/web/National2Web-Extralight.eot"));
var ___CSS_LOADER_URL___13___ = urlEscape(require("./assets/fonts/web/National2Web-Extralight.eot") + "?#iefix");
var ___CSS_LOADER_URL___14___ = urlEscape(require("./assets/fonts/web/National2Web-Extralight.woff"));

// Module
exports.push([module.id, "\n@font-face {\n  font-family: 'National2';\n  font-weight: 300;\n  src: url(" + ___CSS_LOADER_URL___0___ + ");\n  src: url(" + ___CSS_LOADER_URL___1___ + ") format('embedded-opentype'), url(" + ___CSS_LOADER_URL___2___ + ") format('woff');\n}\n\n@font-face {\n  font-family: 'National2';\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL___3___ + ");\n  src: url(" + ___CSS_LOADER_URL___4___ + ") format('embedded-opentype'), url(" + ___CSS_LOADER_URL___5___ + ") format('woff');\n}\n\n@font-face {\n  font-family: 'National2';\n  font-weight: 500;\n  src: url(" + ___CSS_LOADER_URL___6___ + ");\n  src: url(" + ___CSS_LOADER_URL___7___ + ") format('embedded-opentype'), url(" + ___CSS_LOADER_URL___8___ + ") format('woff');\n}\n\n@font-face {\n  font-family: 'National2';\n  font-weight: 700;\n  src: url(" + ___CSS_LOADER_URL___9___ + ");\n  src: url(" + ___CSS_LOADER_URL___10___ + ") format('embedded-opentype'), url(" + ___CSS_LOADER_URL___11___ + ") format('woff');\n}\n\n@font-face {\n  font-family: 'National2';\n  font-weight: 100;\n  src: url(" + ___CSS_LOADER_URL___12___ + ");\n  src: url(" + ___CSS_LOADER_URL___13___ + ") format('embedded-opentype'), url(" + ___CSS_LOADER_URL___14___ + ") format('woff');\n}\n\n:root {\n  --dark-grey: #263137;\n  --light-grey: #3b474b;\n  --charcoal: #121a1d;\n  --blue: #69b8db;\n  --black: #0b0f13;\n  --light-light-grey:#b5b6b6;\n}\n\nbody {\n  font-size: 16px;\n  line-height: 1.5;\n  font-family: 'National2', sans-serif;\n}\n\n.hide {\n  display: none;\n}\n\n.b {\n  font-weight: 700;\n}\n\n.screensaver {\n  position: absolute;\n  z-index: 100;\n}\n", ""]);

