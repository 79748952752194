import { log } from '../utils/console'

const getMeasurementId = () => {
  if (APPLICATION === 'kiosk') return 'UA-135506502-5'
  if (ENVIRONMENT !== 'production') return 'UA-135506502-12'
  if (APPLICATION === 'website') return 'UA-135506502-7'
  return null
}

const GA_MEASUREMENT_ID = getMeasurementId()

log('APPLICATION \t\t_', APPLICATION)
log('ENVIRONMENT \t\t_', ENVIRONMENT)
log('GA_MEASUREMENT_ID \t_', GA_MEASUREMENT_ID)

export let ga = window.ga || (() => {})

if (GA_MEASUREMENT_ID) {
  if (!window.ga) {
    ga = window.ga = (...args) => {
      ga.q.push(args)
    }
  }
  if (!ga.q) ga.q = []
  ga.l = +new Date()

  ga('create', GA_MEASUREMENT_ID, 'auto')
  ga('send', 'pageview')
}
