const ignoredEnvironments = ['production']

const canOutput = ignoredEnvironments.indexOf(ENVIRONMENT) === -1

export function log () {
  if (canOutput) console.log.apply(console, arguments)
}

export function warn () {
  if (canOutput) console.warn.apply(console, arguments)
}

export function err () {
  if (canOutput) console.error.apply(console, arguments)
}

export default log
