import { isWebGLSupported } from '@pixi/utils'
import { isSupportedBrowser } from './device-check'
import '../common/tracking/register'

if (/mobile/i.test(window.navigator.userAgent)) {
  import('../website-mobile')
} else if (isSupportedBrowser() && isWebGLSupported()) {
  import('../website-desktop')
} else {
  document.location.href = './browser-not-supported.html'
}
