import uap from 'uaparser-js'

const parser = uap()

export const isSupportedBrowser = () => {
  if (!navigator.userAgent) return false

  const ua = parser.parse(navigator.userAgent).ua
  const major = parseInt(ua.major)
  if (isNaN(major)) return false

  switch (ua.family) {
    case 'Safari':
      return (major > 9)

    case 'Mobile Safari':
      return (major > 9)

    // Android
    case 'Chrome':
    case 'Chrome Mobile':
      return (major > 63)

    case 'Chrome Mobile iOS':
      return (major > 63)

    case 'Firefox':
      return (major > 24)

    case 'Edge':
      return true
    default:
      return false
  }
}
